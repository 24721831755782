<template>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 text-dark">Hub Dashboard</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">{{ $t('globalTrans.home') }}</a></li>
              <li class="breadcrumb-item active">{{ $t('globalTrans.dashboard') }}</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <Track />
    <Summary />
  </div>
</template>
<script>
  import Track from './Track'
  import Summary from './Summary'
  
  export default {
    name:'Home',
    components: {
      Summary,
      Track
    }
  }
</script>