<template>
  <section class="content">
    <div class="container-fluid dashboard-card">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                      <div class="form-group">
                          <label for="start_date">{{ $t('globalTrans.startDate') }}</label>
                          <input type="date" id="start_date" class="form-control" v-model="search.start_date">
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                      <div class="form-group">
                          <label for="end_date">{{ $t('globalTrans.endDate') }}</label>
                          <input type="date" id="end_date" class="form-control" v-model="search.end_date">
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id <= 2">
                        <div class="form-group">
                            <label for="title">{{ $t('globalTrans.hub') }}</label>
                            <v-select name="merchant_id"
                              v-model="search.hub_id"
                              label="text"
                              :reduce="item => item.id"
                              :options= hubList
                              :placeholder="$t('globalTrans.select')"
                            />
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                      <div class="form-group">
                          <label for="end_date">{{ $t('globalTrans.rider') }}</label>
                          <v-select name="merchant_id"
                            v-model="search.rider_id"
                            label="text"
                            :reduce="item => item.id"
                            :options= riderList
                            :placeholder="$t('globalTrans.select')"
                          />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group" style="margin-top:30px">
                          <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData">
                          <i class="fa fa-search"></i> {{ $t('globalTrans.search') }}
                          </button>
                      </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                      <thead style="width:100%">
                          <tr>
                              <th colspan="17"><h4>Todays Summary</h4></th>
                          </tr>
                          <tr>
                              <th>Total Orders</th>
                              <th>New</th>
                              <th>Previous</th>
                              <th>Total Assign</th>
                              <th>Assign Pending</th>
                              <th>Delivered</th>
                              <th>Partial Request</th>
                              <th>Partial Delivered</th>
                              <th>On Way</th>
                              <th>On Way Pending</th>
                              <th>Cancel Request</th>
                              <th>Cancel</th>
                              <th>Hold</th>
                              <th>Exchange</th>
                              <th>Return Pending</th>
                              <th>Pick Done</th>
                              <th>Active Deliver Agent</th>
                          </tr>
                      </thead>
                      <tbody v-if="todaySummary">
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id">
                              {{ todaySummary.total_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id">
                              {{ todaySummary.new_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ yesterday +'&end_date='+ yesterday + '&destination_hub_id=' + search.hub_id">
                              {{ todaySummary.previous_order }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status=7&status=11'">
                              {{ todaySummary.total_assign }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=2&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status[]=10'">
                              {{ todaySummary.assign_pending }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status[]=14'">
                              {{ todaySummary.delivered }}
                            </router-link>
                          </td>
                          <td>                           
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status[]=13'">
                              {{ todaySummary.partial_request }}
                            </router-link>
                          </td>
                          <td>                           
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status[]=15'">
                              {{ todaySummary.partial_delivered }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.end_date + '&destination_hub_id=' + search.hub_id + '&status[]=11'">
                              {{ todaySummary.on_way }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ yesterday +'&end_date='+ yesterday + '&destination_hub_id=' + search.hub_id + '&status[]=11'">
                              {{ todaySummary.on_way_pending }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&status[]=12'">
                              {{ todaySummary.cancel_request }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&status[]=20'">
                              {{ todaySummary.total_cancel }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&status[]=17'">
                              {{ todaySummary.total_hold }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&exchange=1'">
                              {{ todaySummary.total_exchange }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&status=20&status=21&status=22&status=23&status=24'">
                              {{ todaySummary.return_pending }}
                            </router-link>
                          </td>
                          <td>
                            <router-link target="_blank" :to="'/order?date_parameter_id=4&start_date='+ search.start_date +'&end_date='+ search.start_date + '&destination_hub_id=' + search.hub_id + '&status[]=3'">
                              {{ todaySummary.pick_done }}
                            </router-link>
                          </td>
                          <td>
                             <router-link target="_blank" :to="'/rider?rider_ids='+ todaySummary.active_riders +'&hub_id=' + (authUser.role_id == 7 ? authUser.hub_id : search.hub_id) ">
                              {{ todaySummary.active_delivery_agent }}
                            </router-link>
                          </td>
                      </tbody>
                  </table>
                </div>
              </b-overlay>
              <b-overlay :show="loader">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                    <thead style="width:100%">
                        <tr>
                            <th colspan="15"><h4>Value Summary</h4></th>
                        </tr>
                        <tr>
                            <th>Total Price</th>
                            <th>Delivered Collected (Pcs)</th>
                            <th>Delivered Collected (Value)</th>
                            <th>Partially Collected (Pcs)</th>
                            <th>Partially Collected (Value)</th>
                            <th>Hold</th>
                            <th>On Way</th>
                            <th>Cancel Charge Collected</th>
                            <th>Collected Amount</th>
                            <th>Deposited Pending</th>
                            <th>Deposited HQ</th>
                            <th>Hub Cost</th>
                            <th>Rider Advance</th>
                        </tr>
                    </thead>
                    <tbody v-if="valueSummary">
                        <td>{{ valueSummary.total_price }}</td>
                        <td>{{ valueSummary.delivered_pcs }}</td>
                        <td>{{ valueSummary.delivered_value }}</td>
                        <td>{{ valueSummary.partial_delivered_pcs }}</td>
                        <td>{{ valueSummary.partial_delivered_value }}</td>
                        <td>{{ valueSummary.hold }}</td>
                        <td>{{ valueSummary.on_way }}</td>
                        <td>{{ valueSummary.cancel_collected }}</td>
                        <td>{{ valueSummary.collected_amount }}</td>
                        <td>{{ valueSummary.deposit_pending }}</td>
                        <td>{{ valueSummary.deposited }}</td>
                        <td>{{ valueSummary.hub_cost }}</td>
                        <td>{{ valueSummary.rider_advanced }}</td>
                    </tbody>
                  </table>
                </div>
              </b-overlay>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
  import config from '@/config' 
  import moment from 'moment'  
  export default {
    name:'Summary',
    data() {
      return {
        loader: false,
        yesterday: moment().subtract(1,'d').format('YYYY-MM-DD'),
        today: moment().format('YYYY-MM-DD'),
        search : {
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          rider_id: '',
          hub_id: ''
        },
        todaySummary: null,
        valueSummary: null
      }
    },
    created () {
      this.loadTodaySummary()
      this.loadValueSummary()
    },
    computed : {
      hubList () {
        return this.$store.state.commonObj.hubList
      },
      authUser () {
        return this.$store.state.authUser
      },
      riderList () {
        if (this.authUser.role_id == 7) {
            return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
        }          
        return this.$store.state.commonObj.riderList
      }
    },
    methods: {
      searchData () {
        this.loadTodaySummary()
        this.loadValueSummary()
      },
      async loadTodaySummary () {
        this.loader = true
        const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search  
        const response = await config.getData('/hub/dashboard/today-summary', params)
        this.loader = false
        this.todaySummary = response.data          
      },
      async loadValueSummary () {
        this.loader = true
        const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search 
        const response = await config.getData('/hub/dashboard/value-summary', params)
        this.loader = false
        this.valueSummary = response.data          
      }
    }
  }
</script>