<template>
    <section class="content">
        <div class="container-fluid dashboard-card">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="tracking_id">{{ $t('globalTrans.order_id') }}</label>
                                <input type="text" id="tracking_id" class="form-control" v-model="search.tracking_id">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="mobile">{{ $t('globalTrans.mobile') }}</label>
                                <input type="text" id="mobile" class="form-control" v-model="search.contact_mobile">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="reference_id">{{ $t('globalTrans.reference_id') }}</label>
                                <input type="text" id="reference_id" class="form-control" v-model="search.reference_id">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" style="margin-top:30px">
                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i> {{ $t('globalTrans.search') }}</button>
                                <button class="btn btn-warning btn-sm ml-2" title="Reload" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <b-overlay :show="loader">
                        <div class="table-responsive">
                            <table class="table table-bordered table-sm">
                                <thead style="width:100%">
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Customer Name</th>
                                        <th>Mobile No</th>
                                        <th>Order Date</th>
                                        <th>Status</th>     
                                        <th>Payment</th>                                     
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order,key) in orders" :key="key">
                                        <td @click="orderLog(order.logs)">{{ order.tracking_id }}</td>
                                        <td>{{ order.name }}</td>
                                        <td>{{ order.mobile }}</td>
                                        <td>{{ order.date }}</td>
                                        <td><span :class="'status-'+order.status">{{ getStatus(order.status) }}</span></td>                                         
                                        <td><span :class="'payment-status-'+order.payment_status">{{ order.payment_status != 0 ? getPaymentStatus(order.payment_status) : '' }}</span></td>                                         
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div><!-- /.container-fluid -->

        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header bg-info text-white">
                      <h5 class="modal-title text-center w-100">Order Log</h5>
                      <button type="button" class="close" aria-label="Close" @click="cancelModal">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <table class="table table-bordered table-sm">
                          <tr>
                              <th style="width:30%">Action By</th>
                              <th>Action</th>
                              <th>Date & Time</th>
                          </tr>
                          <tr v-for="(log, index) in order_logs" :key="index">
                              <td>{{ log.operation_by }}</td>
                              <td>{{ log.operation }}</td>
                              <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                          </tr>
                      </table>                      
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                  </div>
              </div>
          </div>
      </div>
    </section>
</template>
<script>
  import config from '@/config' 
  
  export default {
    name:'Track',
    data() {
      return {
        loader: false,
        orderLogModal: false,
        order_logs: [],
        search : {
          tracking_id: '',
          contact_mobile: '',
          reference_id: ''
        },
        orders: []
      }
    },
    methods: {
        reload () {
            this.orders = []
            this.search = {
                tracking_id: '',
                contact_mobile: '',
                reference_id: ''
            }
        },
        async searchData () {
            this.loader = true
            const response = await config.getData('/hub/dashboard/track', this.search)
            this.loader = false
            if (response.status == 200) {
                this.orders = response.data  
            } else {
                this.orders = []
            }    
        },
        getStatus (StatusId) {
            const tmpStatus = this.$store.state.statusList.find(status => status.id == StatusId)
            return this.$t(tmpStatus.text)
        },
        getPaymentStatus (paymentStatus) {
            if (paymentStatus == 1) {
                return 'Due'
            } else if (paymentStatus == 2) {
                return 'Processing'
            } else if (paymentStatus == 3) {
                return 'Paid'
            } else {
                ''
            }
        },
        orderLog (logs) {
            this.order_logs = logs 
            this.orderLogModal = true
        },
        cancelModal () {
            this.orderLogModal = false
        }
    }
  }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    #invoicePOS table th {
        border: 1px solid #000 !important;
    }
    .timeline {
        list-style: none;
        padding: 25px 40px;
        position: relative;
        border: 2px solid #ddd;
    }

    .timeline:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;
    }

    .timeline > li {
        margin-bottom: 20px;
        position: relative;
    }

    .timeline > li:before,
    .timeline > li:after {
        content: " ";
        display: table;
    }

    .timeline > li:after {
        clear: both;
    }

    .timeline > li:before,
    .timeline > li:after {
        content: " ";
        display: table;
    }

    .timeline > li:after {
        clear: both;
    }

    .timeline > li > .timeline-panel {
        width: 46%;
        float: left;
        border: 1px solid #d4d4d4;
        border-radius: 2px;
        padding: 20px;
        position: relative;
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
    }

    .timeline > li > .timeline-panel:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " ";
    }

    .timeline > li > .timeline-panel:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " ";
    }

    .timeline > li > .timeline-badge {
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 1.4em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        margin-left: -25px;
        background-color: #33cc33;
        border: 1px solid #33cc33;
        z-index: 100;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    .timeline > li.timeline-inverted > .timeline-panel {
        float: right;
    }

    .timeline > li.timeline-inverted > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    .timeline > li.timeline-inverted > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .timeline-title {
        margin-top: 0;
        color: inherit;
    }

    .timeline-body > p,
    .timeline-body > ul {
        margin-bottom: 0;
    }

    .timeline-body > p + p {
        margin-top: 5px;
    }

    .timeline-badge i.fa {
        color: #fff;
    }

    p .text-muted {
        font-size: 12px !important;
    }
</style>